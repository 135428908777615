import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const BlogIndex = () => {
	const [articles, setArticles] = useState([]);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "جميع المقالات",
				breadcrumb: "المدونة",
				button: {
					show: true,
					text: "اضافة مقال جديد",
					link: "/blog/create",
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/blogs`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setArticles(
					response.data.data.blogs.map(d => {
						return {
							...d,
							description: d.contents.descriptionInBrev ?? d.description.ar,
							title: d.title.ar ?? "",
						};
					})
				);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const columns = [
		{
			name: "العنوان",
			selector: "title",
			sortable: true,
		},
		{
			name: "",
			selector: "settings",
			cell: row => (
				<ul className="list-inline settingsList">
					<li className="list-inline-item">
						<Link className="bt bt-sm bt-success" to={`/blog/show/${row.id}`}>
							<i className="fas fa-edit"></i>
						</Link>
					</li>
					<li className="list-inline-item">
						<button
							className="bt bt-sm bt-danger"
							onClick={() => handleConfrimDelete(row.id)}
						>
							<i className="fas fa-trash"></i>
						</button>
					</li>
				</ul>
			),
		},
	];

	const handleConfrimDelete = articleID => {
		confirmAlert({
			title: "تأكيد الحذف",
			message: "هل أنت متأكد من رغبتك في الحذف؟",
			buttons: [
				{
					label: "نعم",
					onClick: () => deleteArticle(articleID),
				},
				{
					label: "لا",
					onClick: () => console.log("No"),
				},
			],
		});
	};

	const deleteArticle = articleID => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/blogs/${articleID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<div className="datatable">
				<DataTableExtensions columns={columns} data={articles}>
					<DataTable
						noHeader
						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
						direction="rtl"
						striped
					/>
				</DataTableExtensions>
			</div>
		</section>
	);
};

export default BlogIndex;
