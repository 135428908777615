import logo from "../../assets/images/nour-seen-logo.png";

const Footer = () => {
	return (
		<footer>
			<p>
				Developed by:
				<a href="https://nsn.sa/" target="_blank" rel="noopener noreferrer">
					<img src={logo} alt="nourseen" />
				</a>
				. All Rights Reserved.
			</p>
		</footer>
	);
};

export default Footer;
